import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "../Props/style.css";

const Products = () => {
  const [businessProducts, setBusinessProducts] = useState([]);
  const [individualProducts, setIndividualProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/products`);
        
        // Assuming the API returns all products and we need to filter them by category
        const products = response.data.success ? response.data.products : response.data;
        
        // Filter products by category
        const business = products.filter(product => product.category === 'Business');
        const individual = products.filter(product => product.category === 'Individual');
        
        setBusinessProducts(business);
        setIndividualProducts(individual);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  const ProductCard = ({ product }) => (
    <div className="col-md-3">
      <div className="pro-slider">
        <div className="bg-[#16181E] p-6 rounded h-full flex flex-col justify-between shadow-md hover:shadow-lg transition-shadow duration-300">
          <div>
            <div className="mb-4">
              <img 
                src={product.icon} 
                alt={product.title} 
                className="w-16 h-16 object-contain text-red-500"
              />
            </div>
          <div style={{height: "100px"}}>  
          	<h3 className="text-xl text-white font-semibold mb-2">{product.title}</h3>
          </div>
            <p className="text-sm text-gray-400">{product.description.split(/\s+/).slice(0, 8).join(" ")+"..."}</p>
          </div>
          <div className="mt-4">
            <a 
              href={`/loan/${product._id}`}
              className="decoration-none px-4 py-2" 
              style={{ color: "#c31001", textDecoration: "none" }}
            >
              Apply Now
            </a>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <section className="main-wrap">
          <section className="inr-ban">
        <div className="container h-100">
          <div className="banner-txt h-100 align-content-center m-0">
            <h4 className="text-white mx-auto">Products</h4>
          </div>
        </div>
      </section>

      <section className="product-wrap">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
            <div className="title-prt">
              <h5 data-aos="fade-up" data-aos-duration="2000">Our Product</h5>
              <h2 data-aos="fade-up" data-aos-duration="3000">
                Discover Our Range <br /> Products
              </h2>
            </div>
          </div>

          <div className="title-prt">
            <h3 className="product-category-title">Business</h3>
            <div className="row g-4 pb-5">
              {businessProducts.map((product) => (
                <ProductCard key={product._id} product={product} />
              ))}
            </div>

            <h3 className="product-category-title">Individual</h3>
            <div className="row g-4 pb-5">
              {individualProducts.map((product) => (
                <ProductCard key={product._id} product={product} />
              ))}
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Products;