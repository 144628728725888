import React, { useState } from 'react';
import cibilicon from '../../images/cbl.png'
const CalculatorEMI = () => {
  // Define interest rates
  const interestRates = {
    "personal-loan": 12,
    "pre-approved-loan": 10,
    "used-car-loan": 8,
    "business-loan": 15,
    "home-loan": 9,
  };

  // State management
  const [loanType, setLoanType] = useState('personal-loan');
  const [principal, setPrincipal] = useState('');
  const [tenure, setTenure] = useState('');
  const [interestRate, setInterestRate] = useState(interestRates['personal-loan']);
  const [results, setResults] = useState({
    emi: 0,
    totalAmount: 0,
    totalInterest: 0
  });

  // Handle loan type change
  const handleLoanTypeChange = (e) => {
    const selectedType = e.target.value;
    setLoanType(selectedType);
    setInterestRate(interestRates[selectedType]);
  };

  // Calculate EMI
  const calculateEMI = (e) => {
    e.preventDefault();
    
    const principalAmount = parseFloat(principal);
    const tenureMonths = parseInt(tenure);
    const monthlyInterestRate = interestRate / 1200;

    const emi = (principalAmount *
      monthlyInterestRate *
      Math.pow(1 + monthlyInterestRate, tenureMonths)) /
      (Math.pow(1 + monthlyInterestRate, tenureMonths) - 1);

    const totalAmountPayable = emi * tenureMonths;
    const totalInterestPayable = totalAmountPayable - principalAmount;

    setResults({
      emi: emi || 0,
      totalAmount: totalAmountPayable || 0,
      totalInterest: totalInterestPayable || 0
    });
  };

  // Format currency with 2 decimal places
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount);
  };

  return (
    <section className="main-wrap">
      <div className="container">
        <h2 className="main-title" data-aos="fade-up" data-aos-duration="2000">
          <span>Calculator</span> Tools To Help You Decide
        </h2>

        <nav>
 <div className="d-flex justify-content-center mt-5">
  <div className="nav nav-tabs" id="nav-tab" role="tablist">
    <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true" style={{fontSize:"12px", padding: "8px"}}>EMI Calculator</button>
    <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false" style={{fontSize:"12px", padding: "8px"}}>Pre-Payment Calculator</button>
    <button className="nav-link d-flex align-items-center" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false" style={{fontSize:"12px", padding:"8px"}}><img src={cibilicon} alt="" width="40"/>
            <span>Check CIBIL &amp; Apply</span></button>
  </div>
        </div>
</nav>
<div className="tab-content" id="nav-tabContent">
  <div className="tab-pane fade show active tab-grey p-4" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
      <div className="calculator-form">
        <div className="form-group">
          <select 
            className="form-select"
            value={loanType}
            onChange={handleLoanTypeChange}
          >
            <option value="personal-loan">Personal Loan</option>
            <option value="pre-approved-loan">Pre Approved Loan</option>
            <option value="used-car-loan">Used Car Loan</option>
            <option value="business-loan">Business Loan</option>
            <option value="home-loan">Home Loan</option>
          </select>
        </div>
        <div className="form-group">
          <input
            type="number"
            className="form-control"
            placeholder="Loan Amount"
            value={principal}
            onChange={(e) => setPrincipal(e.target.value)}
          />
        </div>
        <div className="form-group">
          <input
            type="number"
            className="form-control"
            placeholder="Loan Duration (Months)"
            value={tenure}
            onChange={(e) => setTenure(e.target.value)}
          />
        </div>
        <div className="form-group">
          <input
            type="number"
            className="form-control"
            placeholder="Rate of interest (%)"
            value={interestRate}
            readOnly
          />
        </div>
      </div>
      <div className="calculation-prt">
        <ul>
          <li>
            <label>Monthly EMI</label>
            <h4>{formatCurrency(results.emi)}</h4>
          </li>
          <li>
            <label>Total Amount Payable</label>
            <h4>{formatCurrency(results.totalAmount)}</h4>
          </li>
          <li>
            <label>Total Interest Payable</label>
            <h4>{formatCurrency(results.totalInterest)}</h4>
          </li>
          <li>
            <button 
              onClick={calculateEMI}
              className="btn btn-danger w-100"
            >
              <span>Calculate</span>
            </button>
          </li>
        </ul>
      </div>
  </div>
  <div className="tab-pane fade tab-grey p-4" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
      <div>
        <a href="#" className="btn btn-danger w-100"><span>Apply Now</span></a>
      </div>
  </div>
  <div className="tab-pane fade tab-grey p-4" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
      <div>
        <a href="#" className="btn btn-danger w-100"><span>Check CIBIL & Aplly</span></a>
      </div>
  </div>
</div>

      </div>
    </section>
  );
};

export default CalculatorEMI;