import React,  { useState, useEffect } from 'react';
import Carousel from '../Props/HeroPage/Carousel';
import axios from 'axios';
import Navbar from './Navbar';
import AboutUs from './AboutUsPages';
import ProductCarousel from '../Props/HeroPage/ProductCarosel';
import CalculaterEMI from '../Props/HeroPage/CalculaterEMI';
import ContactUs from './ContactUs';
import Footer from './Footer';
import HeroAboutUs from '../Props/HeroPage/AboutUs';
import Info from '../Props/HeroPage/Info';
// import HeroAboutUs from '../Props/HeroPage/AboutUs';
// import Info from '../Props/HeroPage/Info';
// const slides = [
//   {
//     image: 'images/banner1.jpg',
//     alt: 'Slide 1',
//     title: 'Financial Planning For Your Need',
//     description:
//       'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
//   },
//   {
//     image: 'images/banner2.jpg',
//     alt: 'Slide 2',
//     title: 'Financial Planning Made Simple',
//     description:
//       'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.',
//   },
//   {
//     image: 'images/banner3.jpg',
//     alt: 'Slide 3',
//     title: 'Achieve Your Financial Goals',
//     description:
//       'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque.',
//   },
// ];

const HomePage = () => {
  const [slides, setSlides] = useState([]);
  const [loading, setLoading] = useState(true);
  const url = process.env.REACT_APP_BACKEND_URL;
  useEffect(() => {
    async function fetchBanners() {
      try {
        const response = await axios.get(`${url}/banners/active`);
        if (response.data.success) {
          // Get banners from the response data.
          const banners = response.data.data;
          console.log(banners)
          // Sort banners according to priority (lowest number first).
          banners.sort((a, b) => a.priority - b.priority);
          // Map banners to slides format.
          const slidesData = banners.map((banner) => ({
            image: banner.media?.file || '', // Use media file as background image.
            title: banner.title,
            description: banner.description,
            // For this example, we create a single button with the downloadUrl.
            buttons: [
              {
                text: 'Learn More',
                url: banner.downloadUrl
              }
            ]
          }));
          setSlides(slidesData);
        }
      } catch (error) {
        console.error('Error fetching banners:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchBanners();
  }, [url]);

 
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
  <>
  <Carousel slides={slides} autoplay={true} autoplaySpeed={5000} height="h-[600px]" />
  <HeroAboutUs />
      <CalculaterEMI />
  <ProductCarousel />
      <Info />
      {/* <Media /> */}
  </>
  
);
};

export default HomePage;