import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
// AOS for scroll animations
import AOS from "aos";
import "aos/dist/aos.css";

// Import static images and logo
import navImage2 from "../images/nav-img2.jpg";
import navImage from "../images/nav-img.jpg";
import logo from "../images/cropped-logo-blue-new.png";

// Helper: returns a static image based on the label.
const getStaticNavImage = (label) => {
  if (label === "Products") {
    return navImage2;
  }
  if (label === "Investor’s Desk") {
    return navImage;
  }
  return "";
};

const Header = () => {
  // State for dynamic data
  const [products, setProducts] = useState([]);
  const [investorDesk, setInvestorDesk] = useState(null);
  // State for hover and mobile menu behavior
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [settings, setSettings] = useState(null);
  // Timer ref for delaying dropdown hide
  const timerRef = useRef(null);

  const getProductsByCategory = (products) => {
    const grouped = products.reduce((acc, product) => {
      const category = product.category || "Other";
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(product);
      return acc;
    }, {});
    return grouped;
  };

  // Initialize AOS and add scroll listener
  useEffect(() => {
    AOS.init();

    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener("scroll", handleScroll);

    // Cleanup scroll listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Fetch products and investor desk data from APIs concurrently
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [productsRes, investorRes, settingsRes] = await Promise.all([
          axios.get(process.env.REACT_APP_BACKEND_URL + "/products"),
          axios.get(process.env.REACT_APP_BACKEND_URL + "/investor-desk"),
          axios.get(process.env.REACT_APP_BACKEND_URL + "/settings"),
        ]);
        // Use response.data with Axios
        const productsData = productsRes.data; // Expected to be an array
        const investorData = investorRes.data; // Expected to be an object with 'title' and 'sections'
        setProducts(productsData);
        setInvestorDesk(investorData);
        setSettings(settingsRes.data.data);

        console.log("settings", settingsRes.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Helper function to split products array into two columns
  const splitProductsColumns = (products) => {
    const half = Math.ceil(products.length / 2);
    return [
      products.slice(0, half).map((product) => ({
        label: product.title,
        path: `/loan/${product._id}`, // Adjust the path as needed
      })),
      products.slice(half).map((product) => ({
        label: product.title,
        path: `/loan/${product._id}`,
      })),
    ];
  };

  // Helper function to split investor desk sections into two columns.
  // We assume investorDesk.sections is an array.
  const splitInvestorColumns = (sections) => {
    const half = Math.ceil(sections.length / 2);
    return [
      sections.slice(0, half).map((section) => ({
        label: section.title,
        path: `/investor-desk`, // Adjust the path as needed
      })),
      sections.slice(half).map((section) => ({
        label: section.title,
        path: `/investor-desk`,
      })),
    ];
  };

  // Build the navigation data dynamically.
  const navData = [
    { label: "Home", path: "/" },
    { label: "About Us", path: "/about" },
  { label: "Committee", path: "/committee" },  
  {
      label: "Products",
      path: "/products",
      subMenu: {
        // Split the products into 2 columns (if products are loaded)
        categories: getProductsByCategory(products),
      },
    },
    {
      // Use the investor desk title from API; fallback to static text if not yet fetched.
      label: investorDesk ? investorDesk.title : "Investor's Desk",
      path: "/investor-desk",
      subMenu: {
        // Only split sections if investorDesk and its sections exist.
        columns:
          investorDesk && investorDesk.sections && investorDesk.sections.length > 0
            ? splitInvestorColumns(investorDesk.sections)
            : [],
      },
    },
    /* { label: "Media Centre", path: "/media" },*/
  
    { label: "Contact Us", path: "/contact" },
  ];

  // Handle mouse enter for desktop dropdown (with delay clearing)
  const handleMouseEnter = (index) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setHoverIndex(index);
  };

  const handleCategoryEnter = (category) => {
    setHoveredCategory(category);
  };

  // Handle mouse leave with a short delay
  const handleMouseLeave = () => {
    timerRef.current = setTimeout(() => {
      setHoverIndex(null);
    }, 100); // Delay in milliseconds (adjust as needed)
  };

  return (
    <header
      className={`header  sticky-top bg-white ${
        isScrolled ? "shadow-md fixed " : "bg-white"
      }`}
    >
      <div className="container mx-auto px-4">
        <nav className="navbar justify-content-between py-3">
        <div className="container-fluid p-0">  
        {/* Logo */}
          <Link to="/" className="navbar-brand flex items-center space-x-2">
            <img src={settings?.navbarLogo || logo} alt="Logo" className="h-10 w-auto object-contain" width="120px"/>
          </Link>
        <nav className="navigation-menu">  
        <ul className="hidden lg:flex items-center space-x-6 text-gray-800 font-medium m-0">

          {/* Desktop Nav */}
            {navData.map((item, index) => (
              <li
                key={index}
                className="relative group"
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <Link
                  to={item.path}
                  className="text-black hover:text-red-600 transition-colors no-underline"
                >
                  {item.label}
                </Link>

                {/* Categories Dropdown for Products */}
                {item.label === "Products" && hoverIndex === index && (
                  <div className="absolute left-0 top-full mt-2 bg-white shadow-lg rounded-sm flex z-50">
                    {/* Categories Column */}
                    <div className="w-48 border-r bg-white">
                      {Object.keys(item.subMenu.categories).map((category) => (
                        <div
                          key={category}
                          className="p-3 bg-white hover:bg-gray-50 cursor-pointer"
                          onMouseEnter={() => handleCategoryEnter(category)}
                        >
                          {category}
                        </div>
                      ))}
                    </div>

                    {/* Products Column */}
                    {hoveredCategory && (
                      <div className="w-64 p-4">
                        <ul className="space-y-2 bg-white">
                          {item.subMenu.categories[hoveredCategory].map((product) => (
                            <li key={product._id}>
                              <Link
                                to={`/loan/${product._id}`}
                                className="text-black hover:text-red-600 transition-colors block"
                              >
                                {product.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                )}
              </li>
            ))}
            {/* Phone Button for Desktop (only visible on larger screens) */}
            
          </ul>
        
      </nav>
        <a
              href={`tel:${settings?.phoneNumbers?.[0]?.number}`}
              className="hidden lg:inline-block btn btn-outline-primary"
            >
              <i className="fa-solid fa-phone mr-2 text-indigo-900"></i>
              <span>{settings?.phoneNumbers?.[0]?.number}</span>
            </a>
          {/* Hamburger Button for Mobile */}
          <div className="lg:hidden">
            <button
              onClick={() => setIsMobileOpen(!isMobileOpen)}
              className="text-gray-800 focus:outline-none"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                {isMobileOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                )}
              </svg>
            </button>
          <offcanvasNavbar/>
          </div>
        </div>
        </nav>

        {/* Mobile Navigation Menu */}
        {isMobileOpen && (
          <div className="lg:hidden bg-white shadow-md">
            <ul className="flex flex-col space-y-4 p-4">
              {navData.map((item, index) => (
                <li key={index}>
                  <Link
                    to={item.path}
                    onClick={() => setIsMobileOpen(false)}
                    className="block text-black hover:text-red-600 transition-colors no-underline"
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
              {/* Phone number included only in the mobile menu */}
              <li>
                <a
                  href="tel:9414471999"
                  onClick={() => setIsMobileOpen(false)}
                  className="block text-black hover:text-red-600 transition-colors no-underline"
                >
                  <i className="fa-solid fa-phone mr-2 text-indigo-900"></i>
                  +91 941 447 1999
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;