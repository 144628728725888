import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "./slick.css";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import inrBan from "../images/inr-ban.jpg"; // Banner image
import bg4 from "../images/mision-and-vision-dhruva-capital.jpg";

const AboutUs = () => {
  const [team, setTeam] = useState([]);
  const [activeTab, setActiveTab] = useState("board"); // "board" or "leadership"

  useEffect(() => {
    const fetchTeam = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/team`);
        if (response.data.success) {
          setTeam(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching team data:", error);
      }
    };

    fetchTeam();
  }, []);

  // Filter team data into two groups based on category
  const boardTeam = team.filter(member => member.category === "Board of Director");
  const leadershipTeam = team.filter(member => member.category === "Leadership Team");

  // Slider settings for react-slick
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: team.length < 4 ? team.length : 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: team.length < 3 ? team.length : 3,
          slidesToScroll: 3,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: team.length < 2 ? team.length : 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  // Render slider for a given array of members
  const renderSlider = (members) => {
    return (
      <Slider {...settings} className="team-slider mt-5">
        {members.map((member) => (
          <div
            key={member._id}
            className="item"
            data-aos="zoom-in"
            data-aos-duration="2000"
          >
            <div className="team-bx mx-4">
              <div className="team-img">
                <img
                  src={member.image.file}
                  alt={member.image.alt}
                  className="img-fluid"
                />
              </div>
              <h4>
                {member.name} <span>{member.position}</span>
              </h4>
            </div>
          </div>
        ))}
      </Slider>
    );
  };

  return (
    <>
      {/* Banner Section */}
      <section className="inr-ban">
        <div className="container h-100">
          <div className="banner-txt h-100 align-content-center m-0">
            <h4 className="text-white mx-auto">About us</h4>
          </div>
        </div>
      </section>

      {/* About Company Section */}
      <section className="main-wrap">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="abt-txt"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <h5>About the Company</h5>
                <h3>What We Believe</h3>
                <p>
                  The Company is a Non-Banking Financial Company (NBFC), which
                  is engaged in the business of Investment and Financing. The
                  company is registered with Reserve Bank of India as an NBFC,
                  not accepting public deposits u/s 45-IA of the Reserve Bank of
                  India Act, 1934, vide registration number – 10.00098 dated
                  01.09.1999.
                </p>
                <p>
                  The Equity Shares of the company are listed on the Bombay
                  Stock Exchange Limited (BSE Ltd.). The Company was
                  incorporated as a public company on 31.1.1995, and it’s
                  Corporate and Registered offices are situated in Udaipur. The
                  major business activities are undertaken within and around
                  Udaipur only. <br />
                  The Company is neither a subsidiary company of any holding
                  company, nor it has any subsidiary company.
                </p>
              </div>
            </div>            
          </div>
        </div>
      </section>

      {/* Mission/Vision Section */}
      <section className="mv-section">
        <div className="mv-img" data-aos="fade-right" data-aos-duration="2000">
          <img src={bg4} alt="" />
        </div>
        <div className="mv-txt" data-aos="fade-left" data-aos-duration="2000">
          <h4>Our Mission</h4>
          <p> 
            At Dhruva Capital, our mission is to empower small and medium-sized enterprises (MSMEs) and individuals through accessible and innovative financial solutions. We strive to be more than just a lending institution; we're your trusted partner on the path to success. Our mission is rooted in.{" "}
          </p>
          <h4>Our vision</h4>
          <p>
            At Dhruva Capital, our vision is to be the leading financial partner for small and medium-sized enterprises (MSMEs) and individuals across India, starting from Eastern India and expanding nationwide. Rooted in our core values of empowerment, innovation, transparency, impact, and accessibility, our vision guides us towards a future where.
          </p>
          <a href="#" className="btn btn-outline-light mt-4">
            <span>View Our Approach</span>
          </a>
        </div>
      </section>
      {/* Team Section with Separate Carousels */}
      <section className="main-wrap">
        <div className="container">
          <div className="title-prt clr-blk">
            <h5>Management Team</h5>
            <h2>We're Strategists &amp; Sector Specialists</h2>
          </div>
          <div className="mx-0 mt-4">
            <button
              className={`btn ${activeTab === "board" ? " btn-danger text-white " : "btn-outline-danger"} mx-0`}
              onClick={() => setActiveTab("board")}
              >
              <span>Board of Directors</span> 
            </button>
            <button
              className={`btn ${activeTab === "leadership" ? "btn-danger text-white " : "btn-outline-danger"} mx-0`}
              onClick={() => setActiveTab("leadership")}
              >
                <span>Leadership Team</span> 
            </button>
          </div>
              <hr className="m-0"/>
          <div className="team-carousel mt-4">
            {activeTab === "board" && renderSlider(boardTeam)}
            {activeTab === "leadership" && renderSlider(leadershipTeam)}
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;