import React, { useState, useEffect } from "react";

const committees = [
  {
    title: "Audit Committee",
    members: [
      { name: "Ms. Hitu Gambhir Mahajan", category: "Independent Director", designation: "Chairperson" },
      { name: "Mr. Altab Uddin Kazi", category: "Independent Director", designation: "Member" },
      { name: "Mr. Shreeram Bagla", category: "Wholetime Director", designation: "Member" }
    ]
  },
  {
    title: "Nomination And Remuneration Committee",
    members: [
      { name: "Mr. Altab Uddin Kazi", category: "Independent Director", designation: "Chairperson" },
      { name: "Mr. Chanchal Kedia", category: "Independent Director", designation: "Member" },
      { name: "Mr. Hitu Gambhir Mahajan", category: "Independent Director", designation: "Member" }
    ]
  },
  {
    title: "Stakeholder Relationship Committee",
    members: [
      { name: "Mr. Altab Uddin Kazi", category: "Independent Director", designation: "Chairperson" },
      { name: "Mr. Hitu Gambhir Mahajan", category: "Independent Director", designation: "Member" },
      { name: "Mr. Shreeram Bagla", category: "Wholetime Director", designation: "Member" }
    ]
  },
  {
    title: "Composition Of Nomination & Remuneration Committee",
    members: [
      { name: "Mr. Altab Uddin Kazi", category: "Independent Director", designation: "Chairperson" },
      { name: "Mrs. Chanchal Kedia", category: "Independent Director", designation: "Member" },
      { name: "Mr. Hitu Gambhir Mahajan", category: "Independent Director", designation: "Member" }
    ]
  }
];

const CommitteeTable = ({ title, members }) => (
  <div className="card shadow-lg mb-4">
    <div className="card-header bg-danger text-white text-center text-uppercase fw-bold">
      {title}
    </div>
    <div className="card-body">
      <table className="table table-striped table-bordered table-hover text-center">
        <thead className="table-dark">
          <tr>
            <th>Name</th>
            <th>Category</th>
            <th>Designation</th>
          </tr>
        </thead>
        <tbody>
          {members.map((member, index) => (
            <tr key={index}>
              <td>{member.name}</td>
              <td>{member.category}</td>
              <td>{member.designation}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

const CommitteeSection = () => {
  return (
  <>
  <section class="inr-ban">
  	<div class="container h-100">
    	<div class="banner-txt h-100 align-content-center m-0">
        	<h4 class="text-white mx-auto">Committee</h4>
    	</div>
  	</div>
  </section>  
  <section className="main-wrap">
      <div className="container">
        {committees.map((committee, index) => (
          <CommitteeTable key={index} title={committee.title} members={committee.members} />
        ))}
      </div>
   </section>
  </>
  );
};

export default CommitteeSection;
