import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";

const MediaDetails = () => {
  const { id } = useParams(); // Get media ID from URL
  const [media, setMedia] = useState(null);
  const [recentPosts, setRecentPosts] = useState([]);
  const [comment, setComment] = useState({ name: "", phone: "", email: "", message: "" });

  useEffect(() => {
    AOS.init(); // Initialize animations

    // Fetch media details based on ID
    const fetchMediaDetails = async () => {
      try {
        const response = await axios.get(`/api/media/${id}`);
        setMedia(response.data);
      } catch (error) {
        console.error("Error fetching media details:", error);
      }
    };

    // Fetch recent posts
    const fetchRecentPosts = async () => {
      try {
        const response = await axios.get("/api/media/recent");
        setRecentPosts(response.data);
      } catch (error) {
        console.error("Error fetching recent posts:", error);
      }
    };

    fetchMediaDetails();
    fetchRecentPosts();
  }, [id]);

  const handleChange = (e) => {
    setComment({ ...comment, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitted Comment:", comment);
    alert("Comment submitted successfully!");
  };

  if (!media) return <div className="text-center">Loading...</div>;

  return (
    <section className="main-wrap">
      <div className="container">
        <div className="title-prt clr-blk" data-aos="fade-up" data-aos-duration="2000">
          <h5>Media Centre</h5>
          <h2>Media Details</h2>
        </div>

        <div className="row mt-5">
          <div className="col-lg-9">
            <div className="blog-box2">
              <div className="blo-img">
                <img className="img-responsive" src={media.image} alt={media.title} />
              </div>
              <div className="blog-dtl-txt">
                <ul className="date-pagi">
                  <li><i className="fa fa-calendar" /> {media.date}</li>
                </ul>
                <h3>{media.title}</h3>
                <p>{media.content}</p>
                <h4>Eligible items include:</h4>
                <ul>
                  {media.eligibleItems.map((item, index) => (
                    <li key={index}>* {item}</li>
                  ))}
                </ul>
                <div className="blog-footr-prt">
                  <ul className="blog-social">
                    <li>Share this on:</li>
                    <li><a href="#"><i className="fa-brands fa-facebook-f" /></a></li>
                    <li><a href="#"><i className="fa-brands fa-twitter" /></a></li>
                    <li><a href="#"><i className="fa-brands fa-instagram" /></a></li>
                    <li><a href="#"><i className="fa-brands fa-google-plus" /></a></li>
                  </ul>
                  <p className="comment-txt"><span>{media.commentsCount}</span> Comment</p>
                </div>
              </div>
            </div>

            {/* Comment Section */}
            <div className="blog-dtl-txt">
              <h2 className="contact-title"><span>Leave a</span> comment</h2>
              <form className="rd-mailform" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label">Your name</label>
                      <input className="form-control" type="text" name="name" value={comment.name} onChange={handleChange} required />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label">Your phone</label>
                      <input className="form-control" type="phone" name="phone" value={comment.phone} onChange={handleChange} required />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Your e-mail</label>
                      <input className="form-control" type="email" name="email" value={comment.email} onChange={handleChange} required />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Message</label>
                      <textarea className="form-control" name="message" value={comment.message} onChange={handleChange} required />
                    </div>
                  </div>
                </div>
                <button className="btn btn-danger mt-3" type="submit"><span>Send message</span></button>
              </form>
            </div>
          </div>

          {/* Sidebar - Recent Posts */}
          <div className="col-lg-3">
            <div className="category-box">
              <h3>Recent Posts</h3>
              {recentPosts.map((post, index) => (
                <div className="top-rated-bx" key={index}>
                  <a href={`/media/${post.id}`}>
                    <div className="top-rated-img">
                      <img className="img-responsive" src={post.image} alt={post.title} />
                    </div>
                    <div className="top-rated-txt">
                      <h4>{post.title}</h4>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MediaDetails;