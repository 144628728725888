import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Carousel = ({ slides = [], autoplaySpeed = 5000, autoplay = false }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    turnover: '',
    name: '',
    phone: '',
    email: ''
  });
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (!autoplay) return;
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, autoplaySpeed);
    return () => clearInterval(interval);
  }, [autoplay, autoplaySpeed, slides.length]);

  useEffect(() => {
    validateForm(); // Check validation on form changes
  }, [formData]);

  const nextSlide = () => setCurrentSlide((prev) => (prev + 1) % slides.length);
  const prevSlide = () => setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);

  const validateForm = () => {
    let newErrors = {};
    if (!formData.turnover) newErrors.turnover = 'Annual Turnover is required';
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.phone || !/^[0-9]{10}$/.test(formData.phone)) newErrors.phone = 'Valid 10-digit phone number required';
    if (!formData.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) newErrors.email = 'Valid email required';

    setErrors(newErrors);
    setIsFormValid(Object.keys(newErrors).length === 0);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isFormValid) {
      alert('Form submitted successfully');
      setShowModal(false);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <section className="banner">
      <div className="banner-slider relative overflow-hidden">
        <div
          className="flex transition-transform duration-700 ease-in-out h-full"
          style={{ transform: `translateX(-${currentSlide * 100}%)` }}
        >
          {slides.map((slide, index) => (
            <div
              key={index}
              className="w-full h-full flex-shrink-0 relative"
              style={{
                backgroundImage: `url(${slide.image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <div className="ban-inr-sec absolute inset-0 bg-opacity-40 flex items-center">
                <div className="container mx-auto px-6 h-full flex items-center">
                  <div className="text-left text-black max-w-2xl">
                    <h3>{slide.title}</h3>
                    <p>{slide.description}</p>
                    <div className="flex space-x-4 btn-grp mt-3">
                      {slide.buttons?.map((button, buttonIndex) => (
                        <a key={buttonIndex} href={button.url} className="btn btn-danger">
                          <span>Ask An Expert</span>
                        </a>
                      ))}
                      <a className="btn btn-danger" href="#" onClick={() => setShowModal(true)}>
                        <span>Apply Now</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {slides.length > 1 && (
          <>
            <button onClick={prevSlide} className="absolute left-4 top-1/2 -translate-y-1/2 bg-white/80 p-2 rounded-full shadow-md hover:bg-white transition-colors">
              <ChevronLeft className="w-6 h-6 text-gray-800" />
            </button>
            <button onClick={nextSlide} className="absolute right-4 top-1/2 -translate-y-1/2 bg-white/80 p-2 rounded-full shadow-md hover:bg-white transition-colors">
              <ChevronRight className="w-6 h-6 text-gray-800" />
            </button>
          </>
        )}
      </div>

      {showModal && (
        <div className="modal fade show d-block" tabIndex="-1" role="dialog" style={{ backgroundColor: 'rgba(0, 0, 0, 0.95)' }} onClick={() => setShowModal(false)}>
          <div className="modal-dialog modal-md modal-dialog-centered" role="document" onClick={(e) => e.stopPropagation()}>
            <div className="modal-content p-4">
              <div className="modal-header border-0">
                <h5 className="modal-title font-weight-bold">Get Your Loan In 24 Hours.<br /><strong>Start Now.</strong></h5>
                <button type="button" className="close" onClick={() => setShowModal(false)}>
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label>Annual Turnover*</label>
                    <select className="form-control" name="turnover" onChange={handleChange} value={formData.turnover}>
                      <option value="">Select Turnover</option>
                      <option>Less than 10L</option>
                      <option>10L - 50L</option>
                      <option>50L - 1Cr</option>
                      <option>1Cr+</option>
                    </select>
                    {errors.turnover && <small className="text-danger">{errors.turnover}</small>}
                  </div>
                  <div className="form-group">
                    <label>Name*</label>
                    <input type="text" className="form-control" name="name" onChange={handleChange} value={formData.name} placeholder="Name of the Contact Person*" />
                    {errors.name && <small className="text-danger">{errors.name}</small>}
                  </div>
                  <div className="form-group">
                    <label>Phone Number*</label>
                    <input type="tel" className="form-control" name="phone" onChange={handleChange} value={formData.phone} placeholder="Ten digit mobile no.*" />
                    {errors.phone && <small className="text-danger">{errors.phone}</small>}
                  </div>
                  <div className="form-group">
                    <label>Email*</label>
                    <input type="email" className="form-control" name="email" onChange={handleChange} value={formData.email} placeholder="Your email" />
                    {errors.email && <small className="text-danger">{errors.email}</small>}
                  </div>
                  <p className="mt-2">By clicking on "Apply Now", I agree to Dhruva Capital.</p>
                  <button type="submit" className="btn btn-primary btn-block mt-3" disabled={!isFormValid}>
                    Apply Now
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Carousel;
