import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const LoanTemplate = () => {
  const [sectionsData, setSectionsData] = useState(null);
  const [productData, setProductData] = useState(null);
  const { id } = useParams(); // product id from URL

  useEffect(() => {
    const fetchSections = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/products/${id}/sections`
        );
        setSectionsData(response.data);
      } catch (error) {
        console.error("Error fetching sections:", error);
      }
    };
    const fetchProduct = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/products/${id}`
        );
        setProductData(response.data);
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };
    fetchProduct();
    fetchSections();
  }, [id]);
  console.log(sectionsData)

  if (!sectionsData || !productData) {
    return <div>Loading...</div>;
  }

  const { mainSection, quickstepsSection, benefitsSection } = sectionsData;

  return (
    <>
      {/* Banner Section */}
      <section className="inr-ban">
        <div className="container h-100">
          <div className="banner-txt h-100 align-content-center m-0">
            <h4 className="text-white mx-auto">{productData.title}</h4>
          </div>
        </div>
      </section>

      {/* Main Content Section */}
      <section className="main-wrap">
        <div className="container">
          <div className="row">
            {/* Left Content */}
            <div className="col-md-8">
              <div className="abt-txt">
                <h5>Grow with</h5>
                <h3>Dhruva Capital</h3>
                <p>
                  {productData.description}
                </p>
                <h4>Eligibility :</h4>
                <ul>
                  {mainSection.eligibility &&
                    mainSection.eligibility.map((point, index) => (
                      <li key={index}>{point}</li>
                    ))}
                </ul>
                <h4>Application Process :</h4>
                <ul className="doc-list">
                  {mainSection.applicationProcess &&
                    mainSection.applicationProcess.map((step, index) => (
                      <li key={index}>{step}</li>
                    ))}
                </ul>
              </div>
            </div>

            {/* Right Content */}
            <div className="col-md-4">
              <div className="rt-count-prt">
              {mainSection.rateDetails &&
                    mainSection.rateDetails.map((rate, index) => (
                <>
                <div
                key={index}
                className="count-bx"
                data-aos="zoom-in"
                data-aos-duration="2000"
                >
                  <h3>{rate.title}</h3>
                  <p>
                    {rate.details}
                  </p>
                </div>
              </>
                ))}
                </div>
            </div>
          </div>
        </div>
      </section>

      {/* Quicksteps Section */}
      <section className="mv-section">
        <div
          className="mv-img"
          data-aos="fade-right"
          data-aos-duration="2000"
        >
          {quickstepsSection.image ? (
            <img
              className="img-fluid"
              src={quickstepsSection.image}
              alt="Quicksteps Section"
            />
          ) : (
            <img
              className="img-fluid"
              src="https://via.placeholder.com/300x200"
              alt="Placeholder"
            />
          )}
        </div>
        <div
          className="mv-txt"
          data-aos="fade-left"
          data-aos-duration="2000"
        >
          <div className="title-prt">
            <h5 data-aos="fade-up" data-aos-duration="2000">
            {quickstepsSection.subtitle}
            </h5>
            <h2 data-aos="fade-up" data-aos-duration="3000">
            {quickstepsSection.title}
            </h2>
          </div>
          <div className="row mt-5">
            {quickstepsSection.steps &&
              quickstepsSection.steps.map((step, index) => (
                <div key={index} className="col-md-6">
                  <div className="quick-bx">
                    <div className="q-ico">
                      <img
                        className="img-fluid"
                        src={step.icon ? step.icon : "https://via.placeholder.com/50"}
                        alt={step.title}
                      />
                    </div>
                    <h4>{step.title}</h4>
                    <p>{step.content}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="main-wrap">
        <div className="container">
          <div className="title-prt clr-blk">
            
          </div>
          <div className="msme-bx mt-5">
            <div className="table-responsive mt-3">
              <table className="table">
                <tbody>
                  {/* Additional fee/interest details can be mapped here */}
                </tbody>
              </table>
            </div>
          </div>
          <div className="msme-bx mt-5">
            <h3>Benefits Of {productData.title}</h3>
            {benefitsSection.benefits &&
              benefitsSection.benefits.map((benefit, index) => (
                <div key={index} className="benifit-bx" data-aos="zoom-in" data-aos-duration="2000">
                  <h4>
                    <span>*</span> {benefit.benefitTitle}
                  </h4>
                  <p>{benefit.content}</p>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default LoanTemplate;