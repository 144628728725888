import React from 'react'
import img1 from '../../images/transformlives.jpg'
import icon1 from '../../images/icon1.png'
import icon2 from '../../images/icon2.png'
import icon3 from '../../images/icon3.png'
import icon4 from '../../images/icon4.png'
import icon5 from '../../images/icon5.png'
const HeroAboutUs = () => {
  return (
    <>
    <section className="about-sec">
      <div className="abt-img-prt" data-aos="fade-right" data-aos-duration="2000">
        <img src={img1} alt="" />
      </div>
      <div className="abt-rt-txt" data-aos="fade-left" data-aos-duration="2000">
        <h5>About Us</h5>
        <h3>Expand Your <br />Business With Us</h3>
        <p>The Company is a Non-Banking Financial Company (NBFC), which is engaged in the business of Investment and Financing. The company is registered with Reserve Bank of India as an NBFC, not accepting public deposits u/s 45-IA of the Reserve Bank of India Act, 1934, vide registration number – 10.00098 dated 01.09.1999.</p>
        <div className="btn-grp mt-3">
          <a href="#" className="btn btn-light"><span>Apply Now</span></a>
          <a href="#" className="btn btn-outline-light ms-2"><i className="fa-solid fa-download" /> <span>Download App</span></a>
        </div>
      </div>
    </section>
  {/* <section className="main-wrap">
      <div className="container">
        <h2 className="main-title" data-aos="fade-up" data-aos-duration="2000"><span>Calculator</span> Tools To Help You Decide</h2>

        <div id="horizontalTab" className="mt-5">
          <ul className="resp-tabs-list">
              <li>EMI Calculator</li>
              <li>Pre-Payment Calculator</li>
              <li>Eligibility Calculator</li>
          </ul>
          <div className="resp-tabs-container">
              <div className="resp-tab-content-active">
                <div className="calculator-form">
                  <div className="form-group">
                    <select name="" id="" className="form-select">
                      <option value={true}>Loan Type</option>
                      <option value={true}>Personal Loan</option>
                      <option value={true}>Pre Approved Loan</option>
                      <option value={true}>Used Car Loan</option>
                      <option value={true}>Business Loan</option>
                      <option value={true}>Home Loan</option>
                    </select> 
                  </div>
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Loan Amount" /> 
                  </div>
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Loan Duration (Months)" /> 
                  </div>
                  <div className="form-group">
                    <input type="text" className="form-control" placeholder="Rate of interest (%)" /> 
                  </div>
                </div>
                <div className="calculation-prt">
                  <ul>
                    <li>
                      <label>Monthly EMI</label>
                      <h4>₹ 0,00,00</h4>
                    </li>
                    <li>
                      <label>Total Amount Payable</label>
                      <h4>₹ 0,00,00</h4>
                    </li>
                    <li>
                      <label>Total Interest Payable</label>
                      <h4>₹ 0,00,00</h4>
                    </li>
                    <li>
                      <a href="#" className="btn btn-danger w-100"><span>Apply Now</span></a>
                    </li>
                  </ul>
                </div>
              </div>
  
              <div>
                  ...            
              </div>
              
              <div>
                  ...            
              </div>
          </div>
        </div>
      </div>
    </section> */}
    </>
  )
}

export default HeroAboutUs
