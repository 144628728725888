import React, { useState, useEffect } from "react";
import contactImg from '../images/contact-img.jpg';
import "fa-icons";
import axios from 'axios';

const Contact = () => {
  // Form state
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    purpose: "",
    comments: "",
  });

  // Settings state for API data
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitStatus, setSubmitStatus] = useState(null);

  // Handle input changes for form
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus(null);

    try {
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL + '/settings/contactus',
        formData
      );
      
      if (response.data.success) {
        setSubmitStatus({ type: 'success', message: response.data.message });
        setFormData({ name: "", email: "", phone: "", purpose: "", comments: "" }); // Reset form
      } else {
        setSubmitStatus({ type: 'error', message: response.data.message });
      }
    } catch (err) {
      setSubmitStatus({
        type: 'error',
        message: err.response?.data?.message || 'Error submitting contact form'
      });
    }
  };

  // Fetch settings data
  const fetchSettings = async () => {
    try {
      setLoading(true);
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/settings");
      setSettings(response.data.data);
    } catch (err) {
      setError(err.response?.data?.message || 'Error fetching settings');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  return (
    <>
      {/* Contact Form Section */}
      <section className="main-wrap">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="contact-img">
                <img className="img-fluid" src={contactImg} alt="Contact" />
              </div>
            </div>

            <div className="col-md-4">
              <div className="title-prt clr-blk">
                <h5>Contact</h5>
                <h2>Connect With Us</h2>
              </div>

              <form className="form1" onSubmit={handleSubmit}>
                <div className="form-group">
                  <input className="form-control" type="text" name="name" placeholder="Customer Name" value={formData.name} onChange={handleChange} required />
                </div>
                <div className="form-group">
                  <input className="form-control" type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
                </div>
                <div className="form-group">
                  <input className="form-control" type="tel" name="phone" placeholder="Mobile Number" value={formData.phone} onChange={handleChange} required />
                </div>
                <div className="form-group">
                  <select className="form-select" name="purpose" value={formData.purpose} onChange={handleChange} required>
                    <option value="">Purpose</option>
                    <option value="Business Loan">Business Loan</option>
                    <option value="MSME Loan">MSME Loan</option>
                    <option value="Term Loan">Term Loan</option>
                    <option value="Loan For Business Without Security">Loan For Business Without Security</option>
                    <option value="Business Loan EMI Calculator">Business Loan EMI Calculator</option>
                    <option value="Collateral Free Business Loans">Collateral Free Business Loans</option>
                    <option value="Invoice Discounting">Invoice Discounting</option>
                    <option value="Merchant Advance Capital Loan">Merchant Advance Capital Loan</option>
                    <option value="Unsecured Line Of Credit">Unsecured Line Of Credit</option>
                  </select>
                </div>
                <div className="form-group">
                  <textarea className="form-control" name="comments" placeholder="Comments" value={formData.comments} onChange={handleChange} required />
                </div>
                <button className="btn btn-danger w-100" type="submit">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Information Section */}
      <section className="main-wrap contact-page pb-0">
        <div className="container">
          <div className="row">
            {loading ? (
              <div className="col-12 text-center">
                <p>Loading contact information...</p>
              </div>
            ) : error ? (
              <div className="col-12 text-center">
                <p className="text-danger">{error}</p>
              </div>
            ) : settings?.addresses?.length > 0 ? (
              settings.addresses.map((address, index) => (
                <div key={address._id || index} className="col-md-3 col-12">
                  <div className="contact-bx">
                    <h4 className="address-title">{address.title}</h4>
                    <ul className="f-contact">
                      <li>
                        <i className="fa-solid fa-location-dot"></i>
                        <p>{address.location.addressText}</p>
                        {address.location.googleMapsLink && (
                          <a href={address.location.googleMapsLink} target="_blank" rel="noopener noreferrer">
                            View on Google Maps
                          </a>
                        )}
                      </li>
                      {address.contactNumbers.mobile && (
                        <li>
                          <i className="fa-solid fa-mobile-screen-button"></i>
                          <a href={`tel:${address.contactNumbers.mobile}`}>
                            {address.contactNumbers.mobile}
                          </a>
                        </li>
                      )}
                      {address.contactNumbers.telephone && (
                        <li>
                          <i className="fa-solid fa-phone"></i>
                          <a href={`tel:${address.contactNumbers.telephone}`}>
                            {address.contactNumbers.telephone}
                          </a>
                        </li>
                      )}
                      {address.contactNumbers.office && (
                        <li>
                          <i className="fa-solid fa-building"></i>
                          <a href={`tel:${address.contactNumbers.office}`}>
                            {address.contactNumbers.office}
                          </a>
                        </li>
                      )}
                      {address.emails.map((email, emailIndex) => (
                        <li key={emailIndex}>
                          <i className="fa-solid fa-envelope"></i>
                          <a href={`mailto:${email}`}>{email}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-12 text-center">
                <p>No contact information available.</p>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;