import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import './Carousel.css';
import { Link } from "react-router-dom";

const ProductCarousel = () => {
  const [products, setProducts] = useState([]);

  // Fetch products from the API
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/products`
        );
        // Adjust based on your API response format
        if (response.data.success) {
          setProducts(response.data.products);
        } else {
          // If the API returns the products directly:
          setProducts(response.data);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  // Slider settings
  const slideCount = products.length;
  const sliderSettings = {
    dots: true,
    infinite: slideCount > 4,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    // If you want arrows, set arrows: true
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: slideCount > 3,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="product-wrap">
      <div className="container mx-auto px-4">
        {/* Header Section */}
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
          <div className="title-prt">
            <h5>
              Our Product
            </h5>
            <h2>
              Discover Our Range <br/>
              Products
            </h2>
          </div>
        
        <Link
            to={`/products`}
            className="btn btn-outline-danger mt-3"
          >
           <span> All Products</span>
          </Link>        
        </div>

        {/* Carousel */}
        <div className="pro-OverWidth mt-5">
          <Slider {...sliderSettings}>
            {products.map((product) => (
              <div key={product._id} className="pro-slider owl-carousel ">
                <div className="product-box">
                  <div>
                    {/* Icon/Image */}
                    <div className="mb-4 pro-ico">
                      <img
                        src={product.icon}
                        alt={product.title}
                        className="w-16 h-16 object-contain text-red-500"
                      />
                    </div>
                    {/* Title & Description */}
                    <h3 className="text-xl text-white font-semibold mb-2">
                      {product.title}
                    </h3>
                    <p className="text-sm text-gray-400">
                      {product.description.split(/\s+/).slice(0, 8).join(" ")+"..."}
                    </p>
                  </div>
                  {/* Apply Now Link */}
                  <div className="mt-4">
                    <Link
                      to={`/loan/${product._id}`}
                      className="decoration-none px-4 py-2 " 
                      style={{ color:"#c31001", textDecoration:"none" }}
                    >
                      Apply Now
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default ProductCarousel;