import BannerSection from './components/BannerSection';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Media from './components/Media';
import ContactUs from './components/ContactUs';
import './Props/nav-menu.css'
import './Props/style.scss'
import './Props/easy-responsive.css'
import './Props/style.css'
import './App.css';
import './Props/Responsive.css'
import './Props/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import AboutUs from './components/AboutUsPages';
import "@fortawesome/fontawesome-free/css/all.min.css";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import InvestorAnnouncements from './components/Investor\'sDesk';
import MediaDetails from './Props/MediaDetails';
import AboutUsUpdated from './components/AboutUsUpdated';
import LoanTemplate from './Props/LoanTemplate';
import InvestorDesk from './components/InvesterDesk';
import Products from './components/ProductPage';
import Committee from './components/Committee';
import { useEffect } from 'react';
import axios from 'axios';


function App() {

  useEffect(() => {
    async function fetchAndSetFavicon() {
      console.log("settign favicon");
        try {
          const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "/settings");
            const data = response.data.data;
            console.log("favicon", data);
            const faviconUrl = data.favicon; 
            console.log("favicon url", faviconUrl);
            const faviconLink = document.getElementById('favicon');
            if (faviconLink) {
                faviconLink.href = faviconUrl;
            } else {
                console.warn('Favicon link tag not found in document');
            }
        } catch (error) {
            console.error('Failed to fetch favicon:', error);
        }
    }

    fetchAndSetFavicon();
}, []);

  return (
    <>
      <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<BannerSection />} />
        <Route path="/media/media-details/:id" element={<MediaDetails />} />
        <Route path="/investor-desk" element={<InvestorDesk />} />
        <Route path="/media" element={<Media />} />
        <Route path="/loan/:id" element={<LoanTemplate />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/products" element={<Products/>}/>
        <Route path="/committee" element={<Committee/>}/>
      </Routes>
      <Footer />
    </Router>
    </>
  );
}

export default App;
