import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import axios from "axios";

const Media = () => {
  const [mediaItems, setMediaItems] = useState([]);
  const [pressReleases, setPressReleases] = useState([]);

  useEffect(() => {
    // Fetch media data from API (Replace with actual API later)
    const fetchMedia = async () => {
      try {
        const mediaResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/media`);
        setMediaItems(mediaResponse.data);
      } catch (error) {
        console.error("Error fetching media:", error);
      }
    };

    // Fetch press releases data
    const fetchPressReleases = async () => {
      try {
        const pressResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/press-releases`);
        setPressReleases(pressResponse.data);
      } catch (error) {
        console.error("Error fetching press releases:", error);
      }
    };

    fetchMedia();
    fetchPressReleases();
  }, []);

  // Slick Carousel settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  return (
    <>
      {/* Media Carousel */}
      <section className="main-wrap">
        <div className="container">
          <div className="title-prt clr-blk" data-aos="fade-up" data-aos-duration="2000">
            <h5>Media Centre</h5>
            <h2>Featured Stories</h2>
          </div>

          <Slider {...settings} className="media-slider owl-carousel mt-5 slide">
            {mediaItems.map((item, index) => (
              <div className="item" key={index} data-aos="flip-left" data-aos-duration="2000">
                <div className="media-bx">
                  <div className="media-img">
                    <img src={item.image} alt={item.title} />
                    {item.video && (
                      <a href={item.video} className="play-btn">
                        <i className="bi bi-play-circle-fill" />
                      </a>
                    )}
                  </div>
                  <span className="date">{item.date}</span>
                  <h4>{item.title}</h4>
                  <a href='/media-details' className="mt-2">Read More</a>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>

      {/* Press Releases (Non-Carousel) */}
      <section className="press-wrap">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <div className="title-prt clr-blk" data-aos="fade-up" data-aos-duration="2000">
              <h5>Media Centre</h5>
              <h2>Press Releases</h2>
            </div>
            <a href="#" className="btn btn-outline-danger mt-3">
              <span>View All</span>
            </a>
          </div>

          <div className="mt-5">
            {pressReleases.map((release, index) => (
              <div className="press-bx" key={index} data-aos="fade-up" data-aos-duration="2000">
                <h4 className="address-title">{release.date}</h4>
                <h3>{release.title}</h3>
                <p>{release.description}</p>
                <a href={release.link} className="mt-2">Read More</a>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Media;