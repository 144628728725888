import React, { useEffect, useState } from 'react'
import AOS from 'aos';
import { motion } from 'framer-motion';
import 'aos/dist/aos.css';
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";         // Slick core CSS
import "slick-carousel/slick/slick-theme.css";    // Slick theme CSS
import "./Carousel.css";

const Info = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,          // Show 3 testimonials per slide on desktop
    slidesToScroll: 1,        // Scroll one testimonial at a time
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,    // Show 2 testimonials per slide on medium screens
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,    // Show 1 testimonial per slide on small screens
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [activeTab, setActiveTab] = useState("Mobile Apps");

  const sections = [
    {
      title: "Mobile Apps",
      description: "Now, access your loan details 24x7! Download statements & certificates, register service requests, enjoy personalised offers & much more!",
      imageUrl: "https://dhruvacapital.com/wp-content/themes/dhruva-capital/images/phone.png",
    },
    {
      title: "WhatsApp",
      description: "Chat with us on WhatsApp to get real-time loan details and updates, making your experience seamless and hassle-free!",
      imageUrl: "https://dhruvacapital.com/wp-content/themes/dhruva-capital/images/phone.png",
    },
    {
      title: "SMS",
      description: "Receive instant SMS alerts regarding your loan status, payments, and other important updates at your fingertips!",
      imageUrl: "https://dhruvacapital.com/wp-content/themes/dhruva-capital/images/phone.png",
    },
  ];

  return (
    <section className="information-sec">
      <div className="container">
        <div className="title-prt">
          <h5>All the Information</h5>
          <h2>Access Your Loan Information <br />With Ease!</h2>
        </div>

        <div id="verticalTab" className="mt-5 d-flex">
          {/* Left Side Buttons */}
          <ul className="resp-tabs-list flex flex-col">
            {sections.map((section) => (
              <li
                key={section.title}
                className={activeTab === section.title ? "active bg-white text-black" : ""}
                onClick={() => setActiveTab(section.title)}
              >
                {section.title}
              </li>
            ))}
          </ul>

          {/* Right Side Content (Dynamically Updated) */}
          <div className="resp-tabs-container">
            {sections.map((section) => (
              activeTab === section.title && (
                <div key={section.title} className="tab-content flex flex-row justify-between">
                  <div className="tab-text p-6">
                    <h3 className='text-white'>Stay Connected Through <br />{section.title}</h3>
                    <p className='text-white'>{section.description}</p>
                    <div className="btn-grpflex flex flex-row">
                      <a href="#" className="me-2">
                        <img src="https://dhruvacapital.com/wp-content/themes/dhruva-capital/images/g-play.png" alt="Google Play" />
                      </a>
                      <a href="#">
                        <img src="https://dhruvacapital.com/wp-content/themes/dhruva-capital/images/app-store.png" alt="App Store" />
                      </a>
                    </div>
                  </div>
                  <div className="mobile-img w-full max-w-[170px]">
                    <img 
                      className="w-full h-auto" 
                      src={section.imageUrl} 
                      alt={section.title} 
                    />
                  </div>
                </div>
              )
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Info;