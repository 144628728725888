import React, { useState, useEffect } from 'react';

const InvestorDesk = () => {
  const [deskData, setDeskData] = useState(null);
  const [activeSectionId, setActiveSectionId] = useState(null);

  // Fetch the data when the component mounts
  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND_URL + '/investor-desk')
      .then((res) => res.json())
      .then((data) => {
        setDeskData(data);
        if (data.sections && data.sections.length > 0) {
          setActiveSectionId(data.sections[0]._id);
        }
      })
      .catch((err) => {
        console.error('Error fetching investor desk data:', err);
      });
  }, []);

  if (!deskData) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {/* Banner Section */}
      <section className="inr-ban">
        <div className="container h-100">
          <div className="banner-txt h-100 align-content-center m-0">
            <h4 className="text-white mx-auto">Investor's Desk</h4>
          </div>
        </div>
      </section>

      {/* Main Content */}
      <section className="main-wrap">
        <div className="container">
          <div className="title-prt clr-blk">
            {/* Optional title content can go here */}
          </div>

          <div className="row mt-5 g-0">
            {/* Sidebar with Section Titles */}
            <div className="col-md-3">
              <ul className="nav nav-pills flex-column" id="investorTab" role="tablist">
                <li className="nav-item investor-list" role="presentation">
                  <a
                    className="investor-anchor text-decoration-none"
                    href="https://www.bseindia.com/stock-share-price/dhruva-capital-services-ltd/dhruvca/531237/"
                    role="presentation"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Bse Links
                  </a>
                </li>
                {deskData.sections.map((section, index) => (
                  <li key={section._id} className="nav-item investor-list" role="presentation">
                    <a
                      type="button"
                      className={`investor-anchor text-decoration-none ${activeSectionId === section._id ? 'active' : ''}`}
                      id={`tab-${index}`}
                      onClick={() => setActiveSectionId(section._id)}
                    
                    >
                      {section.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            {/* Content for the Active Section */}
            <div className="col-md-9 investor-details">
              <div className="tab-content h-100" id="investorTabContent">
                {deskData.sections.map((section, index) => (
                  <div
                    key={section._id}
                    className={`tab-pane fade h-100 ${activeSectionId === section._id ? 'show active' : ''}`}
                    id={`content-${index}`}
                    role="tabpanel"
                    aria-labelledby={`tab-${index}`}
                  >
                    <div className="pdf-item table-responsive">
                      <table className="table table-striped border-0 table-hover">
                        <tbody>
                          {section.documents && section.documents.length > 0 ? (
                            section.documents.map((doc) => (
                              <tr key={doc._id} >
                                <div className='flex flex-row justify-between'>
                                <td className="border-0">{doc.title}</td>
                                <td className="border-0">
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={doc.fileUrl}
                                    className="btn btn-outline-danger"
                                  >
                                    <i className="fa-solid fa-download" /> <span>Download</span>
                                  </a>
                                </td>
                                </div>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="2" className="border-0">
                                No documents available.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {/* If the table exceeds 10 rows, display the button */}
                    {section.documents && section.documents.length > 10 && (
                      <div className="mt-3">
                        <a
                          href="https://www.bseindia.com/stock-share-price/dhruva-capital-services-ltd/dhruvca/531237/" // Replace with your static link
                          className="btn btn-outline-danger d-block w-100"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View More 
                        </a>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InvestorDesk;